import React from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import { DefaultSeo } from "next-seo";
import "moment/locale/es";
import "moment/locale/pt";
import "moment/locale/zh-cn";
import "moment/locale/ar";
import "moment/locale/fr";
import "moment/locale/ja";
import "moment/locale/ko";
import "moment/locale/vi";
import { appWithTranslation } from "next-i18next";
import { Toaster } from "sonner";
import { GoogleTagManager } from "@next/third-parties/google";

import Providers from "./providers";
import config from "../config";
import SEOConfig from "../config-seo";

/** Font configuration */
import satoshi from "@/assets/fonts/satoshi/font";
import bricolage from "@/assets/fonts/bricolage/font";

const { googleTagManager } = config;

/** Global Style configuration */
import "@/styles/global.css";
function MyApp({ Component, pageProps }) {
  const router = useRouter();

  // Set Gleen AI bot configuration during initial load
  // useEffect(() => {
  //   document.gleenConfig = { company: config?.gleen?.id };
  // }, []);

  return (
    <>
      <style jsx global>{`
        html {
          font-family: ${satoshi.style.fontFamily};
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          font-family: ${bricolage.style.fontFamily};
        }
      `}</style>

      <Providers>
        <Toaster richColors expand={true} />
        <DefaultSeo {...SEOConfig} />
        <main className="text-foreground bg-background relative overflow-hidden">
          <Component {...pageProps} />
        </main>
        {/* <Script src="https://app.gleen.ai/sdk/gleenWidget.js" /> */}
      </Providers>
      <GoogleTagManager gtmId={googleTagManager?.id} />
    </>
  );
}

MyApp.propTypes = {
  Component: PropTypes.func.isRequired,
  pageProps: PropTypes.shape({}).isRequired,
};

export default appWithTranslation(MyApp);
